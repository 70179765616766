import { Avatar, Image } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { httpRequest } from "../helpers/api";

type AppLogoProps = {
  collapsed: boolean;
};

const AppLogo: React.FC<AppLogoProps> = ({ collapsed }) => {
  const [logoUrl, setLogoUrl] = useState<string>("");

  useEffect(() => {
    const fetchDataLogo = async () => {
      try {
        const res: any = await httpRequest.get<any>(
          `/config?configType=COMPANY_INFORMATION`
        );

        if (res) {
          setLogoUrl(res.data.payload.results[0].images.logoWhite[0].imageUrl);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchDataLogo();
  }, []);

  return (
    <LogoEtanaContainer collapsed={collapsed}>
      {collapsed ? (
        <AppAvatar size="large">APP</AppAvatar>
      ) : (
        <AppTitle>
          {/* {process.env.REACT_APP_WEBSITE_NAME} */}
          <AppLogoImage preview={false} src={logoUrl} width={130} />
        </AppTitle>
      )}
    </LogoEtanaContainer>
  );
};

type LogoEtanaContainerProps = {
  collapsed: boolean;
};

const AppLogoImage = styled(Image)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const LogoEtanaContainer = styled.div<LogoEtanaContainerProps>`
  padding: ${({ collapsed }) => (collapsed ? "0" : "3rem 16px")};
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: ${({ collapsed }) => (collapsed ? "center" : "flex-start")};
`;

const AppTitle = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: 20px;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-right: 10%;
`;

const AppAvatar = styled(Avatar)`
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export default AppLogo;
