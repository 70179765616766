import { useIsAuthenticated } from "react-auth-kit";
import { useLocation, Navigate } from "react-router-dom";

const PublicRoute = ({ children, loginPath }: any) => {
  const isAuthenticated = useIsAuthenticated();
  const location = useLocation();

  if (isAuthenticated()) {
    return <Navigate to={loginPath || "/"} state={{ from: location }} replace />;
  }

  return children;
};

export default PublicRoute;
