import React from "react";
import RootNavigator from "./navigation/RootNavigator";
import "./assets/app.css";
import "antd/dist/reset.css";
import ConfigProvider from "./context/ConfigProvider";
import { Alert } from "antd";
import { ThemeProvider } from "styled-components";
import { theme } from "./assets/theme";
import { AuthProvider } from "react-auth-kit";
import { Worker } from "@react-pdf-viewer/core";
import IdleHandler from "./IdleHandler";

const { ErrorBoundary } = Alert;
function App() {
  return (
    <ErrorBoundary>
      <ConfigProvider>
        <ThemeProvider theme={theme}>
          <AuthProvider
            authType="localstorage"
            authName={"_auth"}
            // refresh={refreshToken}
            // cookieDomain={window.location.hostname}
            // cookieSecure={window.location.protocol === "https:"}
          >
            <IdleHandler>
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                <RootNavigator />
              </Worker>
            </IdleHandler>
          </AuthProvider>
        </ThemeProvider>
      </ConfigProvider>
    </ErrorBoundary>
  );
}

export default App;
