import { UserProperties, RoleList } from "../types/user.type";
import { capitalizeFirstLetter } from "./text";
import { IHttpResponse } from "./pagination";
import axios from "axios";
import { IFeaturePermissionV2 } from "@qlibs/react-components/dist/types/permission/permission.type";
import { createRefresh } from "react-auth-kit";
import { httpRequest } from "./api";

export const APP_AUTH_TOKEN = "_auth";

export function saveToken(token: string) {
  return localStorage.setItem(APP_AUTH_TOKEN, token);
}

export function getToken() {
  return localStorage.getItem(APP_AUTH_TOKEN);
}

export function removeToken() {
  return localStorage.removeItem(APP_AUTH_TOKEN);
}

export function getProfile() {
  const authState = localStorage._auth_state;
  const profileData = authState ? JSON.parse(authState) : null;

  return profileData?.userId &&
    profileData?.email &&
    profileData?.name &&
    profileData?.role?.roleName
    ? {
        userId: profileData.userId,
        email: profileData.email,
        name: profileData.name,
        firstName: profileData.firstName,
        lastName: profileData.lastName,
        nickName: profileData.nickName,
        roleName: profileData.roleName,
        roleId: profileData.role.roleId,
        role: profileData.role,
      }
    : null;
}

export function getPermissions(): IFeaturePermissionV2 {
  const authState = localStorage._auth_state;
  const profileData = authState ? JSON.parse(authState) : null;

  return profileData?.userId &&
    profileData?.email &&
    profileData?.roleId &&
    profileData?.roleId &&
    profileData?.role?.roleName &&
    profileData?.role?.permissions
    ? profileData.role.permissions
    : null;
}

export function showRoleName(role: string) {
  if (RoleList[role]) {
    return RoleList[role];
  } else {
    return role
      .split("_")
      .map((item) => capitalizeFirstLetter(item))
      .join(" ");
  }
}

export const getAdminRoles = () => {
  return Object.keys(RoleList).filter((v) => v.includes("admin"));
};

export const getLoginData = async (token?: string) => {
  const headers = {
    Authorization: "Bearer " + (token ? token : getToken()),
  };
  const resultUser = await axios.get<IHttpResponse<UserProperties>>(
    process.env.REACT_APP_BASE_URL + "/users/me",
    {
      headers,
    }
  );

  return {
    user: resultUser.data.payload,
    role: resultUser.data.payload.userType,
  };
};

export const refreshToken = createRefresh({
  interval: 10,
  refreshApiCallback: async (param): Promise<any> => {
    try {
      const response = await httpRequest.post("/auth/refresh-token", param);
      console.log("Refreshing");
      return {
        isSuccess: true,
        newAuthToken: response.data.payload.refresh_token,
        newAuthTokenExpireIn: 10,
        newRefreshTokenExpiresIn: 60,
      };
    } catch (error) {
      console.error(error);
      return {
        isSuccess: false,
      };
    }
  },
});
