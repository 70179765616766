import { Button, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import {
  SESSION_TIMEOUT,
  IDLE_TIMEOUT,
  setLastActivity,
} from "./helpers/lastActivity";
import { useInterval } from "./hooks/useInterval";
import { useSignOut, useIsAuthenticated } from "react-auth-kit";
import { useNavigate } from "react-router-dom";

interface Props {
  children: React.ReactNode;
}

// Production
// const promptBeforeIdle = 30000;

// Testing
const promptBeforeIdle = 600000;

const IdleHandler: React.FC<Props> = ({ children }) => {
  const isLoggedIn: any = useIsAuthenticated();
  const signOut = useSignOut();

  const [remainingSeconds, setRemainingSeconds] =
    useState<number>(IDLE_TIMEOUT);
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    // console.log(remainingSeconds);
  }, [remainingSeconds]);

  const logout = () => {
    signOut();
    localStorage.clear();
    window.location.href = "/";
  };

  const onIdle = async () => {
    setOpen(false);
    logout();
  };

  const onPrompt = () => {
    setOpen(true);
  };

  const onActive = () => {
    setOpen(false);
  };

  const { start, getRemainingTime, activate, pause } = useIdleTimer({
    onIdle,
    onPrompt,
    onActive,
    timeout: IDLE_TIMEOUT,
    promptBeforeIdle,
    throttle: 500,
    crossTab: true,
    startOnMount: false,
    startManually: true,
  });

  useInterval(async () => {
    try {
      if (isLoggedIn()) {
        console.log("isLoggedIn");
      }
    } catch (error) {
      console.error(error);
    }
  }, SESSION_TIMEOUT);

  useEffect(() => {
    if (isLoggedIn()) {
      start();
    } else {
      pause();
    }

    // eslint-disable-next-line
  }, [isLoggedIn()]);

  useEffect(() => {
    const unloadCallback = (event: BeforeUnloadEvent) => {
      setLastActivity();
    };

    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, []);

  useInterval(() => {
    const remainingTimeInSeconds = Math.ceil(getRemainingTime() / 1000);
    setRemainingSeconds(remainingTimeInSeconds);
  }, 500);

  const remainingMinutes = Math.floor(remainingSeconds / 60);
  const remainingSecondsFormatted = remainingSeconds % 60;

  const handleStillHere = () => {
    activate();
  };

  return (
    <React.Fragment>
      {children}
      <Modal
        open={open}
        closable={false}
        title="Are you still here?"
        footer={[
          <Button
            key="stillHereButton"
            type="primary"
            onClick={handleStillHere}
          >
            Im still here
          </Button>,
        ]}
      >
        Logging out in {remainingMinutes}:
        {remainingSecondsFormatted < 10
          ? `0${remainingSecondsFormatted}`
          : remainingSecondsFormatted}
      </Modal>
    </React.Fragment>
  );
};
export default IdleHandler;
