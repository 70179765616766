import moment from "moment";
export const APP_LAST_ACTIVITY = process.env.REACT_APP_ID + "_last_activity";

// Production
// export const IDLE_TIMEOUT = 420000;

// Testing
export const IDLE_TIMEOUT = 1200000;
export const SESSION_TIMEOUT = 3600000; // 1 hour in miliseconds (10^-3 seconds)

export const setLastActivity = () => {
  localStorage.setItem(APP_LAST_ACTIVITY, moment().toISOString());
};
export const getLastActivity = () => {
  let date = localStorage.getItem(APP_LAST_ACTIVITY);
  return moment(date);
};
