import { DownOutlined, LockOutlined, LogoutOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Divider, Dropdown, Menu } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { generateInitialFromName } from "../helpers/generator";
import { getFullName } from "../helpers/name";
import { capitalizeFirstLetter } from "../helpers/text";
import { useSignOut } from "react-auth-kit";
import { getProfile } from "../helpers/auth";

const UserAvatar: React.FC = () => {
  const navigate = useNavigate();
  const signOut = useSignOut();
  const profile = getProfile();

  const initial = generateInitialFromName(profile?.name!)?.toUpperCase();
  // const role = capitalizeFirstLetter(auth()?.user?.userType!);
  let name = profile?.name! ? profile?.name! : "";
  name = name
    ? name
        .split(" ")
        .map((value: any) => capitalizeFirstLetter(value))
        .join(" ")
    : "";

  const handleMenuClick = (e: any) => {
    if (e.key === "profile") {
      navigate("/profile");
    } else if (e.key === "change-password") {
      navigate("/change-password");
    } else {
      signOut();
      localStorage.removeItem("APP_AUTH_TOKEN");
      sessionStorage.removeItem("APP_AUTH_TOKEN");
      navigate("/login");
    }
  };

  const ContentDropdown = (
    <Menu onClick={handleMenuClick}>
      <Menu.ItemGroup
        title={"Welcome, " + (profile?.name!.length > 15 ? profile?.name!.substring(0, 15) + "..." : profile?.name!)}
      >
        <Divider style={{ marginTop: 0, marginBottom: 0 }} />
        <Menu.Item key="profile" icon={<UserOutlined />}>
          My Profile
        </Menu.Item>
        <Menu.Item key="change-password" icon={<LockOutlined />}>
          Change Password
        </Menu.Item>
        <Menu.Item key="logout" icon={<LogoutOutlined />}>
          Logout
        </Menu.Item>
      </Menu.ItemGroup>
    </Menu>
  );

  return (
    <Dropdown overlay={ContentDropdown}>
      <div
        style={{
          display: "flex",
          gap: 8,
          width: "100%",
          alignItems: "center",
        }}
      >
        <AppAvatar size="large">{initial}</AppAvatar>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            lineHeight: 1.5,
          }}
        >
          <span
            style={{
              marginTop: 0,
              marginBottom: 0,
              fontSize: 14,
              fontWeight: 600,
            }}
          >
            {name}
          </span>
          <span
            style={{
              marginBottom: 0,
              fontSize: 12,
              color: "#768499",
            }}
          >
            {profile?.roleName!}
          </span>
        </div>

        <Icon />
      </div>
    </Dropdown>
  );
};

const AppAvatar = styled(Avatar)`
  background-color: ${({ theme }) => theme.colors.ash500};
  color: ${({ theme }) => theme.colors.black};
  margin-right: 10px;
`;

const Icon = styled(DownOutlined)`
  color: ${({ theme }) => theme.colors.charcoal800};
`;

export default UserAvatar;
