export function capitalizeFirstLetter(text: string = "") {
  return text.charAt(0)?.toUpperCase() + text.slice(1);
}

export function exportToCamelCase(str: string) {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index == 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, "");
}

export function showReadableText(str: string) {
  return str.replace(/_/g, " ");
}

export const getYouTubeId = (url?: string) => {
  if (!url) return undefined;

  let match = url.match(
    /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?$/
  );

  return match?.[6];
};

export function limitDescription(text: any, limit = 200) {
  const words = text.split(" ");

  if (words.length > limit) {
    const truncatedWords = words.slice(0, limit);

    const truncatedText = truncatedWords.join(" ") + "...";

    return truncatedText;
  }

  return text;
}
