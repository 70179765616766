import React from "react";
import { Button, Layout, Tooltip, Typography } from "antd";
import { LeftCircleOutlined, RightCircleOutlined } from "@ant-design/icons";
import styled from "styled-components";
import UserAvatar from "../../components/UserAvatar";
import { appVersion, now, thisYear } from "../../helpers/constant";
import useConfigApp from "../../hooks/useConfigApp";
import AppLogo from "../../components/AppLogo";
import Breadcrumbs from "../../components/Breadcrumbs";
import Sidebar from "../../components/Sidebar";
import { Outlet, useLocation } from "react-router-dom";
import { theme } from "../../assets/theme";
import packageJson from "../../../package.json";

const { Header, Sider, Content } = Layout;

type Props = {
  isBgGray?: boolean;
  isNoPadding?: boolean;
};

const AppLayout: React.FC<Props> = ({ isBgGray, isNoPadding }) => {
  const { sidebarCollapsed, toggleSidebar } = useConfigApp();
  const location = useLocation();

  return (
    <Layout style={{ height: "100vh", flexDirection: "row" }}>
      <AppSider
        onCollapse={toggleSidebar}
        trigger={null}
        collapsible
        collapsed={sidebarCollapsed}
        collapsedWidth={70}
        width={250}
        style={{ height: "100vh" }}
      >
        <AppLogo collapsed={sidebarCollapsed} />
        <Sidebar />
      </AppSider>
      {sidebarCollapsed ? (
        <Tooltip placement="right" title={"Expand Sidebar"}>
          <Button
            style={{
              position: "absolute",
              bottom: 0,
              height: 40,
              width: 70,
            }}
            onClick={toggleSidebar}
            type="text"
            icon={
              <RightCircleOutlined
                style={{ color: theme.colors.white }}
                className="icon-collapsed"
              />
            }
          />
        </Tooltip>
      ) : (
        <MinimizeButton
          onClick={toggleSidebar}
          style={{
            position: "absolute",
            bottom: 0,
            height: 40,
            width: 240,
          }}
        >
          <div>
            v{packageJson.version} · ©{thisYear}
          </div>
          <LeftCircleOutlined color={theme.colors.white} />
        </MinimizeButton>
      )}

      <Layout
        id="scroll-content"
        className="site-layout"
        style={{ minHeight: "100vh", overflowY: "scroll" }}
      >
        <AppHeader>
          <Breadcrumbs />
          <AppHeaderAccount>
            <DividerTitle />
            <UserAvatar />
          </AppHeaderAccount>
        </AppHeader>
        <AppContent
          style={{
            background: isBgGray ? "rgb(244, 246, 249)" : "#fff",
            padding: isNoPadding ? 0 : "0px 20px 20px 20px",
          }}
        >
          <Outlet />
        </AppContent>
      </Layout>
    </Layout>
  );
};

const AppContent = styled(Content)`
  height: 100%;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const AppSider = styled(Sider)`
  background-color: ${({ theme }) => theme.colors.primary};
  overflow: auto;
  overflow-x: hidden;
  padding-left: 10px;
`;

const AppHeader = styled(Header)`
  position: relative;
  padding: 0 20px;
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DateNow = styled.div`
  color: ${({ theme }) => theme.colors.black};
  text-align: right;
`;

const AppHeaderAccount = styled.div`
  flex: 1;
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr auto auto;
  align-items: center;
  justify-content: flex-end;
`;

const DividerTitle = styled.div`
  width: 1.5px;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.ash200};
  opacity: 0.4;
  margin: 0 10px;
`;

const MinimizeButton = styled.div`
  width: 100%;
  display: flex;
  height: 60px;
  align-items: center;
  justify-content: space-between;
  padding: 0px 16px;
  cursor: pointer;

  div {
    color: ${({ theme }) => theme.colors.white};
    font-size: 10px;
  }

  svg {
    fill: ${({ theme }) => theme.colors.white};
  }
`;

export default AppLayout;
