export type EUserRole =
  | 'admin'
  | 'admin_ecommerce'
  | 'admin_marketing'
  | 'customer'
  | ''

export enum EUserStatus {
    ACTIVE = "active",
    INACTIVE = "inactive",
}
  

export type UserProperties = {
  userId: string;
  userType: EUserRole;
  name: string;
  email: string;
  phone?: string;
  birthdate?: Date;
  birthcity?: string;
  gender?: 'male'|'female';
  province?: string;
  city?: string;
  address?: string;
  profilePic?: string;
  status: EUserStatus;
  updatedAt?: Date;
  createdAt?: Date;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  nickName?: string;
}
export type RoleList = {
  roleId: string;
  roleName: string;
  roleDescription: string;
  rolePermissions: JSON;
}

export type RoleListResponse = {
  roleId: string;
  roleName: string;
  roleDescription: string;
  rolePermissions: JSON;
}
export type UserPropertiesUpdate = {
  addres?: string;
  birthdate?: string;
  city?: string;
  createdAt?: string;
  email?: string;
  firstName?: string;
  gender?: string;
  isEmailVerified?: boolean;
  isPhoneVerified?: boolean;
  lastName?: string;
  middleName?: string;
  name?: string;
  nickName?: string;
  phone?: string;
  province?: string;
  role: {
    createdAt?: Date;
    isActive?: boolean;
    isDeleted?: boolean;
    permissions?: JSON;
    roleDescription?: string;
    roleId?: string;
    roleName?: string;
    updatedAt?: Date;
  };
  roleId?: string;
  status?: string;
  updatedAt?: Date;
  userId?: string;
}

export interface ICreateUser extends UserProperties {
  password?: string;
}

export type IChangePassword = {
  oldPassword: '',
  newPassword: '',
  retypePassword: ''
}
type IRoleList  = {
  [key: string]: string;
}
export const RoleList: IRoleList = {
  admin: 'Super Admin',
  admin_ecommerce: 'Admin ECommerce',
  admin_marketing: 'Admin Marketing',
  customer: 'Customer'
}

export const initialUser: UserProperties = {
    userId: '',
    name: '',
    email: '',
    phone: '',
    gender: 'male',
    province: '',
    city: '',
    status: EUserStatus.INACTIVE,
    userType: 'customer',
    firstName: '',
    middleName: '',
    lastName: ''
}

export const initialUserUpdate: UserPropertiesUpdate = {
  birthdate: '',
  city: '',
  email: '',
  firstName: '',
  gender: '',
  isEmailVerified: false,
  isPhoneVerified: false,
  lastName: '',
  middleName: '',
  name: '',
  nickName: '',
  phone: '',
  province: '',
  role: {
    isActive: false,
    isDeleted: false,
    roleDescription: '',
    roleId:  '',
    roleName: '',
  },
  roleId: '',
  status: '',
  userId: '',
};

