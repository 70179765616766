import React from "react";
import { Menu } from "antd";
import { ReactComponent as UserGroup } from "../assets/icons/Users.svg";
import { ReactComponent as ListCheck } from "../assets/icons/List_Checklist.svg";
import { ReactComponent as Bar } from "../assets/icons/Bar_Bottom.svg";
import { ReactComponent as House } from "../assets/icons/House.svg";
import { ReactComponent as File } from "../assets/icons/File_Document.svg";
import { ReactComponent as Building } from "../assets/icons/Building.svg";
import { ReactComponent as Shield } from "../assets/icons/Shield.svg";
import { ReactComponent as Gear } from "../assets/icons/Gear.svg";
import { ReactComponent as Info } from "../assets/icons/Info.svg";

import type { MenuProps } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthUser } from "react-auth-kit";
import { getPermissions } from "../helpers/auth";
import { hasPermission } from "@qlibs/react-components/dist/types/permission/hasPermission";

const Sidebar: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const auth = useAuthUser();
  const permissions = getPermissions();
  // const header = useAuthHeader();
  const user = auth();

  // console.log(header())
  // const hasPermission = (finds: EUserRole[] = []) => {
  //   const user = auth()

  //   if(user?.role) {
  //     return finds.includes(user.role.roleName.toLowerCase())
  //   }

  //   return false
  // 	// return finds.includes(role);
  // 	// return true;
  // };

  // const hasPermission = (feature: string, permission: string) => {
  //   const user = auth()

  //   if(user?.role) {
  //     return finds.includes(user.role.roleName.toLowerCase())
  //   }

  //   return false
  // 	// return finds.includes(role);
  // 	// return true;
  // };

  const filterPermission = (items: any[]) => {
    console.log("itemssss", items);
    return items.map((item) => {
      if (item?.permission && item?.permission?.required) {
        let newObject = { ...item };
        if (item.children) {
          newObject.children = filterPermission(newObject.children);
        }

        if (
          hasPermission(
            permissions,
            [...newObject.permission.required],
            newObject.operator
          )
        ) {
          return newObject;
        }

        return false;
      } else {
        return item;
      }
    });
  };

  const MENUS = [
    {
      key: "/",
      label: "Dashboard",

      icon: <House />,
    },
    hasPermission(permissions, ["INTEREST.READ"])
      ? {
          type: "group",
          label: "CUSTOMERS",
          children: [
            hasPermission(permissions, ["INTEREST.READ"])
              ? {
                  key: "/interests",
                  label: "Interests",

                  icon: <Building />,
                }
              : false,
          ],
        }
      : false,
    hasPermission(
      permissions,
      ["PAGES.READ", "PROJECTS.READ", "UNITS.READ", "NEWS_ARTICLES.READ"],
      "OR"
    )
      ? {
          type: "group",
          label: "MASTER DATA",
          children: [
            hasPermission(permissions, ["PAGES.READ"])
              ? {
                  type: "subMenu",
                  label: "Pages",
                  icon: <Bar />,
                  children: [
                    hasPermission(permissions, ["PAGES.HOME_PAGE.READ"])
                      ? {
                          key: "/home-page",
                          label: "Home Page",
                        }
                      : null,
                    hasPermission(permissions, ["PAGES.PROJECT_PAGE.READ"])
                      ? {
                          key: "/project-page",
                          label: "Project Page",
                        }
                      : null,
                    hasPermission(permissions, ["PAGES.COMPARE_PAGE.READ"])
                      ? {
                          key: "/compare-page",
                          label: "Compare Page",
                        }
                      : null,
                    hasPermission(permissions, [
                      "PAGES.NEWS_ARTICLES_PAGE.READ",
                    ])
                      ? {
                          key: "/news-and-articles-page",
                          label: "News & Articles Page",
                        }
                      : null,
                    hasPermission(permissions, ["PAGES.ABOUT_US_PAGE.READ"])
                      ? {
                          key: "/about-us-page",
                          label: "About Us Page",
                        }
                      : null,
                    hasPermission(permissions, ["PAGES.CAREERS_PAGE.READ"])
                      ? {
                          key: "/careers-page",
                          label: "Careers Page",
                        }
                      : null,
                    hasPermission(permissions, [
                      "PAGES.SUSTAINABILITY_PAGE.READ",
                    ])
                      ? {
                          key: "/sustainability-page",
                          label: "Sustainability Page",
                        }
                      : null,
                  ],
                }
              : false,
            hasPermission(permissions, ["PROJECTS.READ"])
              ? {
                  key: "/project-units",
                  label: "Projects & Units",
                  icon: <ListCheck />,
                }
              : false,
            hasPermission(permissions, ["NEWS_ARTICLES.READ"])
              ? {
                  type: "subMenu",
                  label: "News & Articles",
                  icon: <File />,
                  children: [
                    {
                      key: "/news-and-articles",
                      label: "News & Articles",
                    },
                    {
                      key: "/news-and-articles-categories",
                      label: "Categories",
                    },
                  ],
                }
              : false,
            // {
            //   key: "/news-and-articles-type",
            //   label: "Type",
            //   icon: <File />,
            // },
            // {
            //   key: "/news-and-articles-tags",
            //   label: "Tags",
            //   icon: <File />,
            // },
            // {
            //   key: "/facilities",
            //   label: "Facilities",
            //   icon: <File />,
            // },
          ],
        }
      : false,
    hasPermission(permissions, ["ROLES.READ", "USERS.READ"], "OR")
      ? {
          type: "group",
          label: "USERS",
          children: [
            hasPermission(permissions, ["USERS.READ"])
              ? {
                  key: "/user",
                  label: "User",

                  icon: <UserGroup />,
                }
              : false,
            hasPermission(permissions, ["ROLES.READ"])
              ? {
                  key: "/role",
                  label: "Roles & Permissions",

                  icon: <Shield />,
                }
              : false,
          ],
        }
      : false,
    hasPermission(
      permissions,
      ["CONFIG.READ", "PRIVACY_POLICY.READ", "TERMS_AND_CONDITIONS.READ"],
      "OR"
    )
      ? {
          type: "group",
          label: "CONFIG",
          children: [
            hasPermission(permissions, ["CONFIG.READ"])
              ? {
                  key: "/config",
                  label: "Config",
                  icon: <Gear />,
                }
              : false,
            hasPermission(permissions, ["PRIVACY_POLICY.READ"])
              ? {
                  key: "/privacy-policy",
                  label: "Privacy Policy",
                  icon: <Info />,
                }
              : false,
            hasPermission(permissions, ["TERMS_AND_CONDITIONS.READ"])
              ? {
                  key: "terms-and-conditions",
                  label: "Terms and Conditions",
                  icon: <Info />,
                }
              : false,
          ],
        }
      : false,

    // {
    //   type: "group",
    //   label: "CONFIG",
    //   children: [
    //     {
    //       key: "/banner",
    //       label: "Banner",
    //       icon: <PictureOutlined />,
    //     },
    //     {
    //       permission: hasPermissionFromRoles(
    //         user?.role,
    //         FEATURE_PERMISSIONS.PRODUCT.__type,
    //         FEATURE_PERMISSIONS.PRODUCT.LIST.__type
    //       ),
    //       key: "/products",
    //       label: "Product",
    //       icon: <TableOutlined />,
    //     },
    //     {
    //       permission: hasPermissionFromRoles(
    //         user?.role,
    //         FEATURE_PERMISSIONS.PRODUCT_CATEGORY.__type,
    //         FEATURE_PERMISSIONS.PRODUCT_CATEGORY.LIST.__type
    //       ),
    //       key: "/categories",
    //       label: "Category",
    //       icon: <TagOutlined />,
    //     },
    //     {
    //       key: "/tnc-privacy",
    //       label: "Tnc & Privacy Policy",
    //       icon: <InfoCircleOutlined />,
    //     },
    //     {
    //       permission: hasPermissionFromRoles(
    //         user?.role,
    //         FEATURE_PERMISSIONS.FAQ.__type,
    //         FEATURE_PERMISSIONS.FAQ.LIST.__type
    //       ),
    //       key: "/faqs",
    //       label: "FAQs",
    //       icon: <InfoCircleOutlined />,
    //     },
    //     {
    //       permission: hasPermissionFromRoles(
    //         user?.role,
    //         FEATURE_PERMISSIONS.FAQ_GROUP.__type,
    //         FEATURE_PERMISSIONS.FAQ_GROUP.LIST.__type
    //       ),
    //       key: "/faq-group",
    //       label: "FAQ Group",
    //       icon: <InfoCircleOutlined />,
    //     },
    //     {
    //       permission: hasPermissionFromRoles(
    //         user?.role,
    //         FEATURE_PERMISSIONS.ROLE.__type,
    //         FEATURE_PERMISSIONS.ROLE.LIST.__type
    //       ),
    //       key: "/role",
    //       label: "Role",
    //       icon: <UserOutlined />,
    //     },
    //     {
    //       permission: hasPermissionFromRoles(
    //         user?.role,
    //         FEATURE_PERMISSIONS.STORE.__type,
    //         FEATURE_PERMISSIONS.STORE.LIST.__type
    //       ),
    //       key: "/stores",
    //       label: "Store",
    //       icon: <FileTextOutlined />,
    //     },
    //     {
    //       permission: hasPermissionFromRoles(
    //         user?.role,
    //         FEATURE_PERMISSIONS.REGION.__type,
    //         FEATURE_PERMISSIONS.REGION.LIST.__type
    //       ),
    //       key: "/regions",
    //       label: "Region",
    //       icon: <EnvironmentOutlined />,
    //     },
    //     {
    //       permission: hasPermissionFromRoles(
    //         user?.role,
    //         FEATURE_PERMISSIONS.NOTIFICATION_SCHEDULE.__type,
    //         FEATURE_PERMISSIONS.NOTIFICATION_SCHEDULE.LIST.__type
    //       ),
    //       key: "/notification-schedules",
    //       label: "Notification Schedule",
    //       icon: <CalendarOutlined />,
    //     },
    //     {
    //       permission: hasPermissionFromRoles(
    //         user?.role,
    //         FEATURE_PERMISSIONS.SUBSCRIPTION.__type,
    //         FEATURE_PERMISSIONS.SUBSCRIPTION.LIST.__type
    //       ),
    //       key: "/subscriptions",
    //       label: "Subscription",
    //       icon: <BellOutlined />,
    //     },
    //     {
    //       key: "/about-us",
    //       label: "About Us",
    //       icon: <InfoCircleOutlined />,
    //     },
    //   ],
    // },
  ];

  // const items = filterPermission(MENUS) as MenuProps["items"];
  const items = MENUS as MenuProps["items"];

  const convertPathName = () => {
    const pathname = location.pathname.split("/");
    const res = "/" + pathname[1];
    return res;
  };

  const getSelectedParent = () => {
    const currentMenu = convertPathName();
    var parent: string[] = [];
    if (items) {
      for (const group of items) {
        if (group && "children" in group && group.children) {
          for (const submenu of group.children) {
            var current = String(submenu?.key ? submenu.key : "");
            if (submenu && "children" in submenu && submenu.children) {
              for (const item of submenu.children) {
                if (
                  item &&
                  "path" in item &&
                  (item as any).path === currentMenu &&
                  current !== undefined
                ) {
                  parent.push(current);
                }
              }
            }
          }
        }
      }
    }
    return parent;
  };

  return (
    <Menu
      mode="inline"
      style={{ paddingBottom: 40, textDecoration: "none", color: "#fff" }}
      defaultSelectedKeys={[convertPathName()]}
      defaultOpenKeys={getSelectedParent()}
      items={items}
      onClick={({ key }) => {
        navigate(key);
      }}
    />
  );
};
export default Sidebar;
