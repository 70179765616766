import React, { Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Spin } from "antd";
import AppLayout from "../screens/layout/AppLayout";
import { LoadingOutlined } from "@ant-design/icons";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";

const NotFound = React.lazy(() => import("../NotFound"));

const Login = React.lazy(() => import("../screens/auth/Login"));
const ForgotPassword = React.lazy(
  () => import("../screens/auth/ForgotPassword")
);
const ResetPassword = React.lazy(() => import("../screens/auth/ResetPassword"));

const Dashboard = React.lazy(() => import("../screens/dashboard"));
const Profile = React.lazy(() => import("../screens/profile"));
const ChangeMyPassword = React.lazy(
  () => import("../screens/profile/ChangeMyPassword")
);

const User = React.lazy(() => import("../screens/user"));

const Role = React.lazy(() => import("../screens/role"));
const RoleEdit = React.lazy(() => import("../screens/role/Edit"));

const Interests = React.lazy(() => import("../screens/interests"));

const NewsAndArticles = React.lazy(() => import("../screens/newsAndArticles"));
const NewsAndArticlesEdit = React.lazy(
  () => import("../screens/newsAndArticles/Edit")
);
const NewsAndArticlesDetail = React.lazy(
  () => import("../screens/newsAndArticles/Detail")
);

const NewsAndArticlesCategories = React.lazy(
  () => import("../screens/newsAndArticles/categories")
);
const NewsAndArticlesCategoriesEdit = React.lazy(
  () => import("../screens/newsAndArticles/categories/Edit")
);
const NewsAndArticlesCategoriesDetail = React.lazy(
  () => import("../screens/newsAndArticles/categories/Detail")
);

const Config = React.lazy(() => import("../screens/config"));
const PrivacyPolicy = React.lazy(
  () => import("../screens/config/privacyPolicy")
);
const Tnc = React.lazy(() => import("../screens/config/tnc"));

const HomePage = React.lazy(() => import("../screens/page/home"));
const ProjectPage = React.lazy(() => import("../screens/page/project"));
const ComparePage = React.lazy(() => import("../screens/page/compare"));
const NewsAndArticlesPage = React.lazy(
  () => import("../screens/page/newsAndArticles")
);
const AboutUsPage = React.lazy(() => import("../screens/page/aboutUs"));
const CareersPage = React.lazy(() => import("../screens/page/careers"));
const SustainabilityPage = React.lazy(
  () => import("../screens/page/sustainability")
);

const Projects = React.lazy(() => import("../screens/projects"));
const ProjectsEdit = React.lazy(() => import("../screens/projects/edit"));

const RootNavigator: React.FC = () => {
  // const isLoggedIn = useIsAuthenticated();

  const _renderLoading = () => {
    return (
      <div className="flex items-center justify-center w-screen h-screen">
        <Spin
          spinning={true}
          indicator={
            <LoadingOutlined style={{ fontSize: "2rem", color: "#C49268" }} />
          }
        />
      </div>
    );
  };

  return (
    <Suspense fallback={<React.Fragment>{_renderLoading()}</React.Fragment>}>
      <BrowserRouter>
        <Routes>
          {/* Public Routes */}
          <Route
            path="/login"
            element={
              <PublicRoute>
                <Login />
              </PublicRoute>
            }
          />

          <Route
            path="/forgot-password"
            element={
              <PublicRoute>
                <ForgotPassword />
              </PublicRoute>
            }
          />

          <Route
            path="/reset-password/:sessionId"
            element={
              <PublicRoute>
                <ResetPassword />
              </PublicRoute>
            }
          />

          <Route path="*" element={<NotFound />} />

          {/* Private Routes */}
          <Route element={<AppLayout />}>
            <Route
              path="/"
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            />

            <Route
              path="/profile"
              element={
                <PrivateRoute loginPath="/login">
                  <Profile />
                </PrivateRoute>
              }
            />

            <Route
              path="/user"
              element={
                <PrivateRoute loginPath="/login">
                  <User />
                </PrivateRoute>
              }
            />

            <Route
              path="/change-password"
              element={
                <PrivateRoute loginPath="/login">
                  <ChangeMyPassword />
                </PrivateRoute>
              }
            />

            <Route
              path="/role"
              element={
                <PrivateRoute loginPath="/login">
                  <Role />
                </PrivateRoute>
              }
            />
            <Route
              path="/role/add"
              element={
                <PrivateRoute loginPath="/login">
                  <RoleEdit />
                </PrivateRoute>
              }
            />
            <Route
              path="/role/:roleId/edit"
              element={
                <PrivateRoute loginPath="/login">
                  <RoleEdit />
                </PrivateRoute>
              }
            />

            <Route
              path="/interests"
              element={
                <PrivateRoute loginPath="/login">
                  <Interests />
                </PrivateRoute>
              }
            />

            <Route
              path="/news-and-articles"
              element={
                <PrivateRoute loginPath="/login">
                  <NewsAndArticles />
                </PrivateRoute>
              }
            />
            <Route
              path="/news-and-articles/add"
              element={
                <PrivateRoute loginPath="/login">
                  <NewsAndArticlesEdit />
                </PrivateRoute>
              }
            />
            <Route
              path="/news-and-articles/:articleId"
              element={
                <PrivateRoute loginPath="/login">
                  <NewsAndArticlesDetail />
                </PrivateRoute>
              }
            />
            <Route
              path="/news-and-articles/:articleId/edit"
              element={
                <PrivateRoute loginPath="/login">
                  <NewsAndArticlesEdit />
                </PrivateRoute>
              }
            />

            <Route
              path="/news-and-articles-categories"
              element={
                <PrivateRoute loginPath="/login">
                  <NewsAndArticlesCategories />
                </PrivateRoute>
              }
            />
            <Route
              path="/news-and-articles-categories/add"
              element={
                <PrivateRoute loginPath="/login">
                  <NewsAndArticlesCategoriesEdit />
                </PrivateRoute>
              }
            />
            <Route
              path="/news-and-articles-categories/:categoryId"
              element={
                <PrivateRoute loginPath="/login">
                  <NewsAndArticlesCategoriesDetail />
                </PrivateRoute>
              }
            />
            <Route
              path="/news-and-articles-categories/:categoryId/edit"
              element={
                <PrivateRoute loginPath="/login">
                  <NewsAndArticlesCategoriesEdit />
                </PrivateRoute>
              }
            />

            <Route
              path="/config"
              element={
                <PrivateRoute loginPath="/login">
                  <Config />
                </PrivateRoute>
              }
            />
            <Route
              path="/privacy-policy"
              element={
                <PrivateRoute loginPath="/login">
                  <PrivacyPolicy />
                </PrivateRoute>
              }
            />
            <Route
              path="/terms-and-conditions"
              element={
                <PrivateRoute loginPath="/login">
                  <Tnc />
                </PrivateRoute>
              }
            />

            <Route
              path="/project-units"
              element={
                <PrivateRoute loginPath="/login">
                  <Projects />
                </PrivateRoute>
              }
            />
          </Route>

          <Route element={<AppLayout isBgGray={true} />}>
            <Route
              path="/home-page"
              element={
                <PrivateRoute loginPath="/login">
                  <HomePage />
                </PrivateRoute>
              }
            />
            <Route
              path="/project-page"
              element={
                <PrivateRoute loginPath="/login">
                  <ProjectPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/compare-page"
              element={
                <PrivateRoute loginPath="/login">
                  <ComparePage />
                </PrivateRoute>
              }
            />
            <Route
              path="/news-and-articles-page"
              element={
                <PrivateRoute loginPath="/login">
                  <NewsAndArticlesPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/about-us-page"
              element={
                <PrivateRoute loginPath="/login">
                  <AboutUsPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/sustainability-page"
              element={
                <PrivateRoute loginPath="/login">
                  <SustainabilityPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/careers-page"
              element={
                <PrivateRoute loginPath="/login">
                  <CareersPage />
                </PrivateRoute>
              }
            />
          </Route>
          <Route element={<AppLayout isBgGray={true} isNoPadding={true} />}>
            <Route
              path="/project-units/:projectId"
              element={
                <PrivateRoute loginPath="/login">
                  <ProjectsEdit />
                </PrivateRoute>
              }
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
};
export default RootNavigator;
